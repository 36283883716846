// App.js
import React, { useEffect } from "react";
import "./App.css";
import Header from "./Components/Header";
import Features from "./Components/Features";
import WhyChooseUs from "./Components/WhyChooseUs";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "./Components/Footer";
import Content from "./Components/Content";
function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
    });

  }, []);
  return (
    <div className="">
      <nav  className="h-16 bg-sky-900 fixed  z-50 w-full flex items-center top-0  ">
        <img data-aos="zoom-in" className="h-10 md:h-12 ml-4 md:ml-10" src="/vhc_logo.png" alt="" />
      </nav>
      <div data-aos="fade-up" className="h-screen z-50">
        <Header />
      </div>
      <div className="background"></div>
      <Features />
     
      <WhyChooseUs />
      <Content/>
      <Footer/>
    </div>
  );
}

export default App;
