/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
export default function WhyChooseUS() {
  return (
    <div data-aos="zoom-in-up" className="relative min-h-[80vh]  ">
       <h2
        className="text-[2rem] md:text-[3rem] text-center font-bold tracking-tight text-white"
        id="join-heading"
      >
        Why Choose Us
      </h2>
      <div
        className="absolute inset-x-0 top-0 hidden h-1/2  lg:block"
        aria-hidden="true"
      />
     
      <div className="mx-auto max-w-7xl  lg:bg-transparent lg:px-8 py-16">
        <div className="lg:grid lg:grid-cols-12">
          <div className="relative z-10 lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:bg-transparent lg:py-16">
            <div
              className="absolute inset-x-0 h-1/2  lg:hidden"
              aria-hidden="true"
            />
            <div className="mx-auto max-w-md px-6 sm:max-w-3xl lg:max-w-none lg:p-0">
              <div className="aspect-h-6 aspect-w-10 sm:aspect-h-1 sm:aspect-w-2 lg:aspect-w-1">
                <img
                  data-aos="fade-right"
                  className="rounded-3xl object-cover object-center "
                  src="/mobile_1.svg"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="relative md:bg-sky-900 lg:col-span-10 lg:col-start-3 lg:row-start-1 lg:grid lg:grid-cols-10 lg:items-center lg:rounded-3xl">
            <div
              className="absolute inset-0 hidden overflow-hidden rounded-3xl lg:block"
              aria-hidden="true"
            >
              <svg
                className="absolute bottom-full left-full -translate-x-2/3 translate-y-1/3 transform xl:bottom-auto xl:top-0 xl:translate-y-0"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-sky-500"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                />
              </svg>
              <svg
                className="absolute top-full -translate-x-1/3 -translate-y-1/3 transform xl:-translate-y-1/2"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-sky-500"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                />
              </svg>
            </div>
            <div
              data-aos="fade-left"
              className="relative mx-auto max-w-md space-y-6 px-6 py-12 sm:max-w-3xl sm:py-16 lg:col-span-6 lg:col-start-4 lg:max-w-none lg:p-0"
            >
              <p className="text-lg text-white">
                <span className="font-semibold text-[#9bea35]">Innovation:</span> Stay ahead in
                the rapidly evolving Health Care landscape with our AI-driven
                solutions.
              </p>
              <p className="text-lg text-white">
                <span className="font-semibold text-[#9bea35]">Tailored Solutions:</span> We
                understand the unique needs of your Health Care practice and
                provide customized Virtual Assistant solutions to meet them.
              </p>{" "}
              <p className="text-lg text-white">
                <span className="font-semibold text-[#9bea35]">User-Friendly Interface:</span>{" "}
                Our Virtual Assistant is designed for ease of use, ensuring
                seamless integration into your existing Health Care systems.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
