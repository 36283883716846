import React from "react";

const Content = () => {
  return (
    <div className="text-white flex justify-center min-h-[40vh] items-center mb-16 w-full"  data-aos="zoom-in-up">
      <p className="text-center font-bold text-3xl  max-w-5xl">
        Ready to elevate your healthcare services? Experience the future of
        patient care with VHC.ai. Embrace innovation, enhance efficiency, and
        transform healthcare delivery today!
      </p>
    </div>
  );
};

export default Content;
