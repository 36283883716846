
export default function Header() {
  return (
    <div className="z-50">
      <div className="relative flex h-screen items-center isolate overflow-hidden  z-50 ">
   
        <div className="mx-auto max-w-3xl py-32 sm:py-48 lg:py-56">
          <div className=" sm:mb-8 sm:flex sm:justify-center px-8 md:px-0">
            <div
              data-aos="zoom-in"
              className="relative rounded-full px-3 py-1 text-sm leading-6 text-white ring-1 ring-white/80 hover:ring-white/20"
            >
              Presenting{" "}
              <span className="font-semibold text-[#9bea35]">VHC.ai</span>,
              acronym for Virtual Health Care.
            </div>
          </div>
          <div className="text-center mt-12  md:mt-0">
            <h1
              data-aos="zoom-in-up"
              className="text-4xl font-bold tracking-tight text-white sm:text-6xl"
            >
              Discover the Future of Health Care with AI-Powered Virtual
              Assistants
            </h1>
            <p
              data-aos="zoom-in-up"
              className="mt-12 md:mt-6 text-lg leading-8 text-white px-8 md:px-0"
            >
              Embark on a revolutionary journey with our cutting-edge Health
              Care Virtual Assistant. Harnessing the power of Artificial
              Intelligence, we're redefining patient care, improving efficiency,
              and transforming Health Care delivery.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
