const features = [
  {
    name: "Smart Patient Interactions",
    description:
      "Our Virtual Assistant ensures seamless and intelligent communication, providing patients with instant support and guidance.",
  },
  {
    name: "Appointment Management",
    description:
      "Streamline your scheduling process effortlessly, allowing both patients and staff to save time and resources.",
  },
  {
    name: "Medical Information Retrieval",
    description:
      "Access accurate and relevant medical information swiftly, enhancing diagnostic accuracy and decision-making.",
  },
  {
    name: "24/7 Availability",
    description:
      "Our Virtual Assistant is always ready to assist, offering around-the-clock support to patients and Health Care professionals.",
  },
  {
    name: "Data Security",
    description:
      "Your patients' privacy is our priority. Our AI-powered solution ensures the highest standards of data security and confidentiality.",
  },
  {
    name: "Remote Monitoring",
    description:
      "Monitor patients remotely, collecting vital health data and providing timely interventions, fostering proactive Health Care management.",
  },
];

// function QuoteIcon() {
//   return (
//     <svg aria-hidden="true" width={105} height={78}>
//       <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z" />
//     </svg>
//   );
// }
const Features = () => {
  return (
    <div className="content min-h-[80vh]">
      <section
        className="text-white font-bold text-center text-[2rem] md:text-[3rem]"
        data-aos="fade-up"
      >
        Key Features
      </section>

      <section
        id="testimonials"
        aria-label="What our customers are saying"
        className=" py-4 sm:py-10 mt-4 block mb-16"
      >
        <ul
          role="list"
          className="mx-auto mt-4 px-4 md:px-24 lg:px-72 grid md:grid-cols-2 grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3  "
          //   className="mx-auto mt-4 px-4 md:px-72 max-w-none flex flex-wrap w-full gap-8 justify-center"
        >
          {features.map((testimonial, columnIndex) => (
            <li data-aos="zoom-in-up" key={columnIndex} className=" ">
              <figure className="relative px-12 flex items-center flex-col rounded-2xl ring-1 ring-sky-800 backdrop-blur-[10px] hover:backdrop-blur-sm   p-6 shadow-xl shadow-slate-900/10">
                <img className="h-12 w-12 " src="/vheart.svg" />

                <h5 className="text-center font-bold text-[#9bea35] mt-4">
                  {testimonial.name}
                </h5>

                <p className="text-sm  text-center select-none tracking-tight text-white  mt-4">
                  {testimonial.description}
                </p>
              </figure>
            </li>
          ))}
        </ul>
        {/* </Container> */}
      </section>
    </div>
  );
};

export default Features;
