
  export default function Footer() {
    return (
      <footer className="">
        <div className="mx-auto max-w-7xl px-6 py-12 ">
          
          <div className="mt-8 text-center">
            <p className="text-center font-bold text-lg leading-5 text-white">
              &copy; 2024 vhc.ai, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    )
  }
  